const avatarConst = {
    AVATAR_LIST: [
        "/avatar/user-icon.png",
        "/avatar/bear-avatar.png",
        "/avatar/dog-avatar.png",
        "/avatar/fox-avatar.png",
        "/avatar/penguin-avatar.png"
    ],
    DEFAULT: "/avatar/user-icon.png"
}

export default avatarConst
